<template>
  <b-modal ref="hold-on-modal"
           v-model="modalState"
           hide-footer
           :static="true">
    <div class="position-relative">
      <div class="modal-head d-flex align-items-center justify-content-between">
        <p class="m-0">Total</p>
        <p class="m-0">0.99{{ currency }}</p>
      </div>
      <div id="solid-payment-form">
        <div class="modal-pay-buttons d-flex flex-column align-items-stretch">
          <div id="apple-pay-button-container" ref="appleBtnContainer"></div>
          <div id="paypal-button" ref="paypalBtnContainer"></div>
          <p class="modal-pay-button__delimiter">or</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import * as Processing from 'processing';
import { deleteCookie } from '@/main';

export default {
  name: 'BillingModal',
  props: [
    'isModalShow',
    'product',
    'currency',
  ],
  data: () => ({
    redirectUrl: '',
  }),
  computed: {
    modalState: {
      get() {
        return this.isModalShow;
      },
      set(value) {
        this.$emit('onstatechange', value);
      },
    },
  },
  watch: {
    product(newValue) {
      this.initForm(newValue);
    },
  },
  methods: {
    onSuccess() {
      deleteCookie('applicationLink');
      window.location.href = this.redirectUrl;
    },
    onError() {
      deleteCookie('applicationLink');
      this.$router.push('/success');
    },
    onFail() {
      deleteCookie('applicationLink');
      this.$router.push('/success');
    },
    onMounted() {
      this.$store.dispatch('clickHouseEvent', { event_type: 'form_mounted' });
    },
    initForm() {
      this.$refs.appleBtnContainer.innerHTML = '';
      this.$refs.paypalBtnContainer.innerHTML = '';
      Processing.Processing.initOptimalForm(
        {
          product_uuid: this.product,
          event_id: this.$store.state.event_id,
          containerId: 'solid-payment-form',
          redirect_page: `${process.env.VUE_APP_DOMAIN}/#/success/`,
          paypalContainerId: 'paypal-button',
          email: window.localStorage.getItem('email'),
          formParams: {
            submitButtonText: 'Pay',
            isCardHolderVisible: false,
            headerText: 'Enter your debit or credit card details (from merchant)',
            titleText: '',
            formTypeClass: 'flat',
            isSolidLogoVisible: false,
            googleFontLink: '//fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
            cardBrands: ['visa', 'mastercard', 'maestro', 'discover'],
            'font-family': 'Roboto',
          },
          styles: {
            form_body: {
              'min-width': 'unset',
            },
          },
          applePayButtonParams: {
            enabled: true,
            containerId: 'apple-pay-button-container',
            color: 'black',
          },
        },
        {
          onSuccess: this.onSuccess,
          onError: this.onError,
          onFail: this.onError,
          onMounted: this.onMounted,
        },
      )
        .then((response) => {
          this.redirectUrl = response.data.redirect_page;
          if (response.status === 'subscription_exists') {
            window.location.href = this.redirectUrl;
          }
        });
    },
  },
  mounted() {
    this.initForm();
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &.show {
    display: block;
  }

  &-head {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #5B5B5B;
    margin-bottom: 24px;
  }

  &-loading-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    z-index: 101;
  }

  &-pay-button {
    &__delimiter {
      font-size: 17px;
      line-height: 25px;
      color: #ACB2C0;
      text-align: center;
      margin: 0;
      position: relative;
      padding: 32px 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }
    }
  }
}
</style>
